<template>
  <div class="nav_header_wrapper">
    <div class="nav_header">
      <div class="nav_header__logo">
        <img src="@/assets/images/home/logo.png" alt="" />
      </div>
      <div class="nav_header__list">
        <div
          class="nav_header__item"
          v-for="item in list"
          :key="item.id"
          :class="{ active: item.id === activeIndex }"
          @click="changeTab(item.id, item.url)"
          @mouseenter="enter(item)"
        >
          {{ item.name }}
        </div>
      </div>

      <div
        class="nav_header__contact"
        @mouseenter="imgEnter"
        @mouseleave="imgLeave"
        @click="chat"
      >
        <img :src="img" alt="" />
        联系客服
      </div>
    </div>
    <transition name="fade">
      <div class="nav_select" v-show="show" @mouseleave="leave">
        <div class="bd"></div>
        <div
          class="nav_select_item"
          v-for="(item, idx1) in selectList"
          :key="idx1"
        >
          <div class="nav_select_item_left">
            <img :src="item.img" alt="" />
            <div class="nav_select_item_left_title">{{ item.title }}</div>
          </div>
          <div class="nav_select_item_right">
            <div
              class="nav_select_item_right_wraper"
              :class="{ nav_select_item_bd: idx1 === 1 }"
            >
              <div
                class="nav_select_item_li"
                v-for="(item1, idx2) in item.li"
                :key="idx2"
                @click="goto(item1.url)"
              >
                {{ item1.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  props: {

  },
  data () {
    return {
      show: false,
      activeIndex: 0,
      img: require('@/assets/images/home/icon.png'),
      list: [{
        id: 0,
        name: '首页',
        url: '/index'
      }, {
        id: 1,
        name: '产品介绍',
        url: '/product'
      }, {
        id: 2,
        name: '成功案例',
        url: '/case'
      }, {
        id: 3,
        name: '解决方案',
        url: '/solve'
      }, {
        id: 4,
        name: '关于我们',
        url: '/about'
      }],
      selectList: [
        {
          img: require('@/assets/images/home/select2.png'),
          title: '智慧政务高效工作平台',
          li: [
            {
              desc: '智能办文',
              url: '/product/znbw'
            }, {
              desc: '智能办会',
              url: '/product/znbh'
            }, {
              desc: '移动办公',
              url: '/product/ydbg'
            }, {
              desc: '互动交流',
              url: '/product/hdjl'
            }, {
              desc: '电子会务',
              url: '/product/dzhw'
            }, {
              desc: '智慧饭堂',
              url: '/product/zhft'
            }, {
              desc: '行政导航',
              url: '/product/xzdh'
            }, {
              desc: '工作日程',
              url: '/product/gzrc'
            }, {
              desc: '智慧图书',
              url: '/product/zhts'
            }, {
              desc: '更多智慧应用',
              url: '/product/zhyy'
            }
          ]
        },
        {
          img: require('@/assets/images/home/select1.png'),
          title: '智慧政务综合服务平台',
          li: [
            {
              desc: '智慧党建',
              url: '/product/zhdj'
            }, {
              desc: '微信综合服务平台',
              url: '/product/zmhd'
            }, {
              desc: '市民随手拍',
              url: '/product/smssp'
            }, {
              desc: '教育招生',
              url: '/product/jyzs'
            }, {
              desc: '村居通',
              url: '/product/cjt'
            }, {
              desc: '企业服务',
              url: '/product/qyfw'
            }
          ]
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    changeTab (id, url) {
      this.activeIndex = id
      this.$router.push(url)
    },
    imgEnter () {
      this.img = require('@/assets/images/home/qq.png')
    },
    imgLeave () {
      this.img = require('@/assets/images/home/icon.png')
    },
    enter (item) {
      if (item.id === 1) {
        this.show = true
      } else {
        this.show = false
      }
    },
    leave () {
      this.show = false
    },
    goto (url) {
      this.$router.push(url)
    },
    chat () {
      this.$chat()
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
