import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      component: () => import('@/views/home'),
      meta: {
        title: '首页'
      }
    },
    {
      path: '/product',
      component: () => import('@/views/product'),
      redirect: '/product/zmhd',
      meta: {
        title: '产品介绍'
      },
      children: [
        {
          path: 'zmhd',
          component: () => import('@/views/product/zmhd'),
          meta: {
            title: '政民互动'
          }
        },
        {
          path: 'ydbg',
          component: () => import('@/views/product/ydbg'),
          meta: {
            title: '移动办公'
          }
        },
        {
          path: 'hdjl',
          component: () => import('@/views/product/hdjl'),
          meta: {
            title: '互动交流'
          }
        },
        {
          path: 'zhft',
          component: () => import('@/views/product/zhft'),
          meta: {
            title: '智慧饭堂'
          }
        },
        {
          path: 'dzhw',
          component: () => import('@/views/product/dzhw'),
          meta: {
            title: '电子会务'
          }
        },
        {
          path: 'xzdh',
          component: () => import('@/views/product/xzdh'),
          meta: {
            title: '行政导航'
          }
        },
        {
          path: 'znbh',
          component: () => import('@/views/product/znbh'),
          meta: {
            title: '智能办会'
          }
        },
        {
          path: 'zhts',
          component: () => import('@/views/product/zhts'),
          meta: {
            title: '智慧图书'
          }
        },
        {
          path: 'zhdj',
          component: () => import('@/views/product/zhdj'),
          meta: {
            title: '智慧党建'
          }
        },
        {
          path: 'cjt',
          component: () => import('@/views/product/cjt'),
          meta: {
            title: '村居通'
          }
        },
        {
          path: 'znbw',
          component: () => import('@/views/product/znbw'),
          meta: {
            title: '智能办文'
          }
        },
        {
          path: 'gzrc',
          component: () => import('@/views/product/gzrc'),
          meta: {
            title: '工作日程'
          }
        },
        {
          path: 'aijc',
          component: () => import('@/views/product/aijc'),
          meta: {
            title: 'AI监测'
          }
        }
      ]
    },
    {
      path: '/case',
      component: () => import('@/views/cases'),
      meta: {
        title: '成功案例'
      }
    },
    {
      path: '/solve',
      component: () => import('@/views/solve'),
      redirect: '/solve/home',
      meta: {
        title: '解决方案'
      },
      children: [
        {
          path: 'home',
          component: () => import('@/views/solve/home'),
          meta: {
            title: '解决方案'
          }
        },
        {
          path: 'interact',
          component: () => import('@/views/solve/interact'),
          meta: {
            title: '互动交流'
          }
        },
        {
          path: 'meeting',
          component: () => import('@/views/solve/meeting'),
          meta: {
            title: '智能会务'
          }
        },
        {
          path: 'xzdhjjfa',
          component: () => import('@/views/solve/xzdhjjfa'),
          meta: {
            title: '行政导航解决方案'
          }
        },
        {
          path: 'gzrcjjfa',
          component: () => import('@/views/solve/gzrcjjfa'),
          meta: {
            title: '工作日程解决方案'
          }
        },
        {
          path: 'office',
          component: () => import('@/views/solve/office'),
          meta: {
            title: '智能办文'
          }
        },
        {
          path: 'zhft',
          component: () => import('@/views/solve/zhft'),
          meta: {
            title: '智慧饭堂'
          }
        },
        {
          path: 'servey',
          component: () => import('@/views/solve/ai'),
          meta: {
            title: 'AI监测'
          }
        }
      ]
    },
    {
      path: '/about',
      component: () => import('@/views/about'),
      meta: {
        title: '关于我们'
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
