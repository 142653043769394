<template>
  <div class="nav_footer">
    <div class="nav_footer_wraper">
      <div class="nav_footer_detail">
        <div class="nav_footer_detail_service">
          <img
            src="@/assets/images/home/ercode.png"
            alt=""
            class="nav_footer_detail_service_scan"
          />
          <div class="nav_footer_detail_service_item">
            <div class="nav_footer_detail_service_item_title">
              微信扫码关注，了解更多产品动态
            </div>
            <div class="nav_footer_detail_service_item_phone">
              <img src="@/assets/images/home/phone.png" alt="" />
              咨询热线：0769-23034776
            </div>
            <div>
              <img src="@/assets/images/home/location.png" alt="" />
              联系地址: 东莞市南城天安数码城F3栋506室
            </div>
          </div>
        </div>
        <div class="nav_footer_detail_contact">
          <div class="nav_footer_detail_contact_qq">
            <img src="@/assets/images/home/icon.png" alt="" />
            在线QQ（点击联系客服）
          </div>
          <div class="nav_footer_detail_contact_chat">
            <img src="@/assets/images/home/chat.png" alt="" />
            预约试用产品（点击留下您的联系方式）
          </div>
        </div>
      </div>
      <div class="nav_footer_copyright">
        <div class="nav_footer_copyright_left">
          ©2013厚实软件All Rights Reserved
        </div>
        <div class="nav_footer_copyright_right">
          备案号：粤ICP备19114884号 粤公网安备 44190002000375号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  props: {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
