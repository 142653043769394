import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import $ from 'jquery'
import '@/style/reset.scss'
import chat from '@/util/chat'
Vue.config.productionTip = false
Vue.prototype.$jQuery = $
Vue.prototype.$chat = chat
Vue.use(ElementUI)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
