<template>
  <div id="app">
    <nav-header />
    <transition name="fade">
      <router-view />
    </transition>
    <nav-footer />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader'
import NavFooter from '@/components/NavFooter'
export default {
  name: 'App',
  components: {
    NavHeader,
    NavFooter
  }
}
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-avtive {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
